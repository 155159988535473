.icon {
  --size: 1rem;
  fill: currentColor;
  display: block;
  height: var(--size);
  line-height: 1;
  width: var(--size);

  &.sizeAuto {
    width: auto;
    height: auto;
  }
}
