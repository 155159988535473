@use 'src/components/UI/Loader/Loader.scss';

#__tealiumGDPRecModal {
  .privacy_prompt_content1 {
    font-size: 14px !important;
    @media only screen and (max-width: 768px){
      padding: 0;
    }
  }
  .privacy_prompt_container1 {
    overflow-y: unset !important;
    width: 100vw;
  }
  .privacy_prompt1 {
    background-color: rgba(1, 51, 101, 0.9) !important;
    padding: 10px 1% !important;
    @media only screen and (max-width: 768px) {
      padding: 16px !important;
    }
  }
  .privacy_prompt_container1 .__button {
    border-radius: 50vmax;
    font-size: 12px !important;
    height: 40px;
    @media only screen and (max-width: 768px) {
      width: 100% !important;
    }
  }
  .privacy_prompt_container1 {
    font-family:  'Poppins',NeueHaas, 'Helvetica Neue', Arial, sans-serif !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 64px;
    @media only screen and (max-width: 1024px) {
      padding: 0 32px;
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      padding: 0;
    }
  }

  .privacy_prompt_content21 {
    width: 25% !important;
    @media only screen and (max-width: 768px) {
      width: 100% !important;
    }
  }

  .privacy_prompt_content1 {
    width: 70% !important;
    @media only screen and (max-width: 1024px) {
      padding: 0 32px;
    }
    @media only screen and (max-width: 768px) {
      width: 100% !important;
      padding: 0;
    }
  }

  .privacy_prompt_content2 {
    width: 221px !important;
    height: 40px !important;
    flex-grow: 0;
    margin: 39px 16px 8px 123px;
    font-size: 14px !important;
    font-weight: 600 !important;
    background-color: #005abb !important;
    color: #c8e1f6 !important;
    padding: 0 12px 0 12px !important;
    border: 0.4px solid #333 !important;
    border-radius: 20px;
    @media only screen and (max-width: 768px) {
      width: 100% !important;
      margin: 0 auto !important;
      margin-top: 0.7rem !important;
    }
  }

  #consent_prompt_pref {
    display: block;
    align-items: center;
    float: right !important;
    margin-right: 1rem !important;
    @media only screen and (max-width: 768px) {
      width: 100% !important;
      margin: 0 auto !important;
      margin-top: 0.4rem !important;
    }
  }

  #consent_prompt_pref a {
    text-decoration: none !important;
  }

  a.privacy_prompt_content2 {
    display: block;
    width: 221px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #c8e1f6 !important;
    float: right;
    background-color: transparent !important;
    padding: 0.68rem 0 !important;
    border: 0.4px solid #c8e1f6 !important;
    margin: 0;
    @media only screen and (max-width: 768px) {
      width: 100% !important;
      margin: 0 auto !important;
      margin-top: -0.6rem !important;
    }
  }
}

#consent_prompt_submitNo {
  position: relative;
  z-index: 1;
}

.lazy-load-wrapper {
  height: 100%;
  width: 100%;
  max-width: 100vw;

  img {
    width: 100%;
    height: 100%;
    max-width: 100vw;
    object-fit: cover;
    vertical-align: bottom;
  }
}

span.lazy-load-image-background {
  font-size: 0;
}

.pac-container {
  z-index: 1201;
}
