@use 'mixins/breakpoints' as breakpoints;
@use '@styles/functions' as func;

html {
  scroll-behavior: smooth !important;
  --scroll-behavior: smooth !important;
}
.is-scrolled ~ #main-viewport {
  .placement__anchor-buttons {
    top: 0;
    @include breakpoints.up(xxl) {
      top: func.px-to-rem(94px);
    }
  }
}
.placementAnchor {
  height: 1px;
  visibility: hidden;
}
