:root {
  // PALETTE
  --cyprus: #00303c;
  --sherpaBlue: #014758;
  --halfBaked: #83c8bb;
  --jaggedIce: #bce7df;
  --boulder: #767676;
  --alto: #d8d8d8;
  --wildSand: #f6f6f6;
  --greenPea: #247534;
  --tulipTree: #f0a63c;
  --black: #000000;
  --white: #ffffff;
  --grey: #333333;
  --silver-grey: #b8b8b8;
  --whiteSmoke: #f8f8f8;
  --porcelain: #f1f2f2;

  // red
  --thunderBird: #d32113;
  --rustyRed: #bb251a;
  --lightRed: #fde1e1;
  --ligh1Red: #e4414b;
  --light3Red: #f8d7da;

  // yellow
  --yellow: #fdb930;
  --lightYellow: #ffefcf;
  --darkYellow: #faa424;
  --light1Yellow: #ffd043;

  // blue
  --blue: #1c4da1;
  --darkBlue: #253d7f;
  --lightBlue: #c7e4f8;
  --light1Blue: #4069b2;

  // gray
  --ultraLightGray: #f6f6f6;
  --lightGray: #d8d8d8;
  --light1Gray: #6f6e6f;
  --mediumGray: #b0b0b0;
  --darkGray: #767676;

  // green
  --green: #00703b;
  --lightGreen: #c6e6db;
  --light1Green: #00703b;
  --darkGreen: #004c27;

  //platinum
  --platinum: #e0e0e0;

  // gold
  --gold: #cc9900;

  // orange
  --light1Orange: #f15f31;

  // purple
  --ultraLightPurple: #d6d1ee;
  --lightPurple: #e7e5f0;
  --light1Purple: #52449b;
  --purple: #43358d;
  --darkPurple: #352c76;

  // ----------- THEME COLORS ----------------------------
  --primary-dark: var(--black);
  --secondary-dark: var(--darkBlue);
  --tertiary-dark: var(--sherpaBlue);

  --primary-light: var(--grey);
  --secondary-light: var(--lightBlue);
  --tertiary-light: var(--alto);
  --quaternary-light: var(--boulder);

  --success: var(--green);
  --successDark: var(--darkGreen);
  --warning: var(--tulipTree);
  --error: var(--thunderBird);
  --error-dark: var(--rustyRed);
  --error-background: var(--lightRed);

  // ----------- FONTS -----------------------------------
  --font-family: var(--font-montserrat), sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-bold: 600;

  // FONT SIZES
  --font-size-body: 1rem;
  --font-size-body2: 0.875rem;
  --font-size-h1: 2.5rem;
  --font-size-h2: 2rem;
  --font-size-h3: 1.5rem;
  --font-size-h4: 1.25rem;
  --font-size-h5: 1.25rem;
  --font-size-h6: 1.25rem;
  --font-size-subtitle1: 0.75rem;
  --font-size-subtitle2: 0.625rem;

  // ----------- INTERMEDIATE LAYER COLORS -----------------------

  // backgrounds
  --background-primary-dark: var(--primary-dark);
  --background-secondary-dark: var(--blue);
  --background-tertiary-dark: var(--tertiary-dark);
  --background-primary-light: var(--primary-light);
  --background-secondary-light: var(--secondary-light);
  --background-tertiary-light: var(--tertiary-light);
  --background-quaternary-light: var(--quaternary-light);
  --background-quinary-light: var(--porcelain);

  // text
  --text-primary-dark: var(--primary-dark);
  --text-secondary-dark: var(--secondary-dark);
  --text-tertiary-dark: var(--tertiary-dark);
  --text-primary-light: var(--white);
  --text-secondary-light: var(--secondary-light);
  --text-tertiary-light: var(--tertiary-light);

  // border
  --border-primary-dark: var(--primary-dark);
  --border-secondary-dark: var(--secondary-dark);
  --border-tertiary-dark: var(--tertiary-dark);
  --border-primary-light: var(--primary-light);
  --border-secondary-light: var(--secondary-light);
  --border-tertiary-light: var(--tertiary-light);

  // buttons
  --button-contained-primary-background: var(--primary-dark);
  --button-contained-primary-color: var(--text-primary-light);
  --button-contained-primary-border: var(--primary-dark);
  --button-contained-primary-background-hover: var(--primary-light);
  --button-contained-primary-border-hover: var(--primary-light);

  --button-contained-secondary-background: var(--blue);
  --button-contained-secondary-color: var(--text-primary-light);
  --button-contained-secondary-border: var(--blue);
  --button-contained-secondary-background-hover: var(--secondary-dark);
  --button-contained-secondary-border-hover: var(--secondary-dark);

  --button-contained-tertiary-background: var(--secondary-light);
  --button-contained-tertiary-color: var(--primary-dark);
  --button-contained-tertiary-border: var(--tertiary-light);
  --button-contained-tertiary-background-hover: var(--secondary-light);
  --button-contained-tertiary-border-hover: var(--tertiary-light);

  --button-outlined-primary-color: var(--primary-dark);
  --button-outlined-primary-border: var(--primary-dark);
  --button-outlined-primary-border-hover: var(--primary-dark);

  --button-outlined-secondary-color: var(--primary-light);
  --button-outlined-secondary-border: var(--darkPurple);
  --button-outlined-secondary-background-hover: var(--lightGray);
  --button-outlined-secondary-border-hover: var(--darkPurple);

  //virtual mirror css override
  --vm-components-icon-mobile-bg: rgba(255, 255, 255, 0.3);
  --vm-components-icon-mobile-fill: rgb(255 255 255);

  --vm-components-icon-desktop-bg: rgb(0 0 0);
  --vm-components-icon-desktop-fill: rgb(255 255 255);

  --vm-primary-black-100: var(--button-contained-primary-background);
  --vm-primary-white-100: var(--text-primary-light);

  --vm-default-font: var(--font-montserrat), sans-serif;
  --vm-default-font-bold: var(--font-montserrat), sans-serif;
  --vm-default-font-medium: var(--font-montserrat), sans-serif;

  // icons
  --icon-primary-dark: var(--primary-dark);
  --icon-success: var(--success);
}
