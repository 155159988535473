.loader-container {
  display: none;
}

.loader {
  margin: auto;
  border-radius: 100%;
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loader-rect {
  animation: fade 0.78125s linear infinite;
}

.loader-rect-1 {
  animation-delay: -0.7486979167s;
}

.loader-rect-2 {
  animation-delay: -0.7161458333s;
}

.loader-rect-3 {
  animation-delay: -0.68359375s;
}

.loader-rect-4 {
  animation-delay: -0.6510416667s;
}

.loader-rect-5 {
  animation-delay: -0.6184895833s;
}

.loader-rect-6 {
  animation-delay: -0.5859375s;
}

.loader-rect-7 {
  animation-delay: -0.5533854167s;
}

.loader-rect-8 {
  animation-delay: -0.5208333333s;
}

.loader-rect-9 {
  animation-delay: -0.48828125s;
}

.loader-rect-10 {
  animation-delay: -0.4557291667s;
}

.loader-rect-11 {
  animation-delay: -0.4231770833s;
}

.loader-rect-12 {
  animation-delay: -0.390625s;
}

.loader-rect-13 {
  animation-delay: -0.3580729167s;
}

.loader-rect-14 {
  animation-delay: -0.3255208333s;
}

.loader-rect-15 {
  animation-delay: -0.29296875s;
}

.loader-rect-16 {
  animation-delay: -0.2604166667s;
}

.loader-rect-17 {
  animation-delay: -0.2278645833s;
}

.loader-rect-18 {
  animation-delay: -0.1953125s;
}

.loader-rect-19 {
  animation-delay: -0.1627604167s;
}

.loader-rect-20 {
  animation-delay: -0.1302083333s;
}

.loader-rect-21 {
  animation-delay: -0.09765625s;
}

.loader-rect-22 {
  animation-delay: -0.0651041667s;
}

.loader-rect-23 {
  animation-delay: -0.0325520833s;
}

.loader-rect-24 {
  animation-delay: 0s;
}
