@use '@styles/_variables.scss' as variables;

@mixin up($key) {
  $value: map-get(variables.$grid-breakpoints, $key);
  @if $value {
    @media (min-width: $value) {
      @content;
    }
  } @else {
    @media (min-width: $key) {
      @content;
    }
  }
}

@mixin down($key) {
  $value: map-get(variables.$grid-breakpoints, $key);
  @if $value {
    @media (max-width: $value) {
      @content;
    }
  } @else {
    @media (max-width: $key) {
      @content;
    }
  }
}

@mixin only($key) {
  $value: map-get(variables.$grid-breakpoints, $key);
  @if $value {
    @media only screen and (max-width: $value) {
      @content;
    }
  } @else {
    @media only screen and (max-width: $key) {
      @content;
    }
  }
}

@mixin between($key1, $key2) {
  $value1: map-get(variables.$grid-breakpoints, $key1);
  $value2: map-get(variables.$grid-breakpoints, $key2);

  @if not $value1 {
    $value1: $key1;
  }

  @if not $value2 {
    $value2: $key2;
  }

  @media (min-width: $value1) and (max-width: $value2) {
    @content;
  }
}
